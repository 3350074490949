import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import {
  Route,
  createRoutesFromElements,
  RouterProvider,
  createHashRouter,
  useLocation,
  Outlet
} from "react-router-dom";

import {
  updateUser,
  ndflUpdate,
  setDemo,
  setPrizes,
} from "./actions/widgetActions";
import apiRefresh from "./api/apiRfresh";
import { reverseDate, getInitialState, getScrollbarWidth, openModal } from "./utils/helpers";
import { getProfile, getProfileBalance } from "./redux/reducers/userReducer";
import { setListLang } from "./redux/reducers/listlangReducer";
import { getAllCards } from "./redux/reducers/prizesReducer";
import { addToCart, getBasket } from "./redux/reducers/cartReducer";
import { getSettings, setCategories } from "./redux/reducers/settingsReducer";
import { getNDFLStatus } from "./redux/reducers/ndflReducer";

import HeaderMenu from "./components/HeaderMenu/HeaderMenu";
import MenuModal from "./components/MenuModal/MenuModal";

import Certificates from "./screens/Certificates";
import Bank from "./screens/Bank";
import Phone from "./screens/Phone";
import SBP from "./screens/SBP";
import Profile from "./screens/Profile";
import Cart from "./screens/Cart/Cart";
import Orders from "./screens/Orders";
import CertificateDetailed from "./screens/CertificateDetailed";
import NDFLScreen from "./screens/NDFLScreen";

import kz from './lang/kz';
import ru from './lang/ru';
import by from './lang/by';

import "./App.css";

const AppLayout = () => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ready, setReady] = useState(false);
  const scrollWidth = getScrollbarWidth();

  const ndfl = useSelector((state) => state.ndfl);
  const user = useSelector((state) => state.user);
  const taxSettings = useSelector((state) => state.settings.payment_settings.tax);
  const lang = useSelector((state) => state.listLang.lang);
  const memoizedTaxUser = useMemo(() => +taxSettings?.user, [taxSettings?.user]);

  const handleClickOpenModal = () => {
    openModal(scrollWidth);
    setIsModalVisible(true);
  };

  const getUserToken = async () => {
    let refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      const response = await apiRefresh().get()
      .then(res => {
        localStorage.setItem('userRefreshToken', res.data.data.access_token);
      })
      .catch((err) => console.log(err));

      return response;
    }
  };

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [pathname]);

  useEffect(() => {
    const initialNdflState  = getInitialState('ndflState');
    if (initialNdflState ) {
      dispatch(ndflUpdate(initialNdflState));
    } 
  }, [dispatch]);
  
  useEffect(() => {
    getUserToken();
  }, []);

  useEffect(() => {
    if (lang === 'kz') {
      dispatch(setListLang(kz));
    } else if (lang === 'ru') {
      dispatch(setListLang(ru));
    } else if (lang === 'by') {
      dispatch(setListLang(by));
    }
  }, [dispatch, lang]);

  useEffect(() => {
    dispatch(getSettings()).then(res => {
      if (res.payload.success) {
        const settings = res.payload.data;
        dispatch(setCategories({ settings }));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfile()).then(res => {
      const data = res.payload.data;
      dispatch(updateUser({ ...data, date_of_birth: reverseDate(data?.date_of_birth)}));
    });

    dispatch(getProfileBalance()).then(res => {
      const data = res.payload.data;
      dispatch(updateUser({ points: data?.balance || 0 }));
    });

    dispatch(getAllCards()).then(res => {
      if (res.payload.success) {
        const data = res.payload.data
        let prize_list = data.map((item) => {
          return {
            guid_1c: item.guid_1c,
            id: item.id,
            kod: item.kod,
            name: item.name,
            category: item.category,
            imageUrl: item.file_link,
            link: item.reception_locations,
            rules: item.limitations,
            terms_of_use: item.terms_of_use,
            description: item.description,
            step: item.step,
            available: item.in_stock,
            expire_time: item.validity_period,
            validity_period_nominal: item.validity_period_nominal,
            nominal: item.nominal,
            min_nominal: item.min_nominal,
            max_nominal: item.max_nominal,
            user_discount: item.user_discount,
            user_markup: item.user_markup,
            company_discount: item.company_discount,
            company_markup: item.company_markup,
          };
        });
        dispatch(setPrizes({ prize_list }));
      }
    });

    dispatch(getNDFLStatus()).then(res => {
      const data = res.payload.data;
      dispatch(ndflUpdate({...data}))
    });
  }, [dispatch]);

  useEffect(() => {
    if (!memoizedTaxUser) return;

    dispatch(getBasket()).then(res => {
      if (res.payload.success && res.payload.data.eps) {
        const data = res.payload.data.eps;
        const totalAmount = data.reduce((sum, item) => sum + +item.nominal * +item.quantity, 0);

        dispatch(addToCart({
          eps: data.map(item => ({
            ...item,
            id: item.index_number,
            tax: memoizedTaxUser
          })),
          totalAmount: totalAmount
        }))
      }
    });
  }, [dispatch, memoizedTaxUser])

  useEffect(() => {
    localStorage.setItem('ndflState', JSON.stringify(ndfl));
    localStorage.setItem('userState', JSON.stringify(user));
  }, [ndfl, user]);

  const renderDemoHeader = () => {
    return (
      <div className="header">
        <div className="header_top">
          <img src="./your_logo.png" className="big_logo" />
          <div className="selector">
            <div
              style={{
                cursor: "pointer",
                background: !user.isDemo ? "#F3F4F6" : "#ed186b",
                color: !user.isDemo ? "#6b7280" : "#fff",
              }}
              onClick={() => {
                let css = `.prize_box .prize_button {background: #EFEFFD !important; color: #1E72EC !important;}
                  .widget_container {background: #EFEFFD;}
                  .button_icon {filter: invert(100%);}
                  * {font-family: 'Inter';}
                  .menu_item_selected {color: #1E72EC !important; border-bottom-color: #1E72EC !important;,}
                  .button {background: #1E72EC !important; color: #FFF !important}
                  .search_input_container {border-color: #1E72EC !important; border-radius: 9px !important;}
                  .prize_categories {border-color: #1E72EC !important; border-radius: 9px !important;}`;
                let head =
                  document.head || document.getElementsByTagName("head")[0];
                let style = document.createElement("style");
                style.id = "custom";
                head.appendChild(style);
                style.type = "text/css";
                if (style.styleSheet) {
                  style.styleSheet.cssText = css;
                } else {
                  style.appendChild(document.createTextNode(css));
                }
                dispatch(setDemo({ isDemo: true }));
              }}
              className="api"
            >
              API
            </div>
            <div
              style={{
                cursor: "pointer",
                background: user.isDemo ? "#F3F4F6" : "#ed186b",
                color: user.isDemo ? "#6b7280" : "#fff",
              }}
              onClick={() => {
                let style = document.getElementById("custom");
                if (style) {
                  style.remove();
                }

                dispatch(setDemo({ isDemo: false }));
              }}
              className="widget"
            >
              Виджет
            </div>
          </div>
          <div className="search" onClick={handleClickOpenModal}>
            <img src="./search.png" className="search_icon" />
            <div className="search_text">Поиск по сайту</div>
          </div>

          <div className="user">
            <div className="user_text_block">
              <div className="lk" onClick={handleClickOpenModal}>Личный кабинет</div>
              <div className="user_name" onClick={handleClickOpenModal}>Катерина Петрова</div>
            </div>
            <img src="./user_logo.png" className="user_icon" onClick={handleClickOpenModal}/>
          </div>
        </div>

        {isModalVisible ? (
          <MenuModal setIsModalVisible={setIsModalVisible} />
        ) : null}
        <HeaderMenu />
      </div>
    );
  };

  return (
    <>
      {/* <div className="demo_header_container">{renderDemoHeader()}</div> */}
      <div className="widget_container">
        <Outlet />
      </div>
    </>
  );
};

const router = createHashRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route path="/" element={<Certificates />} />
      <Route path="/bank_card" element={<Bank />} />
      <Route path="/mobile" element={<Phone />} />
      <Route path="/sbp" element={<SBP />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/ndfl" element={<NDFLScreen />} />
      <Route path="/order_history" element={<Orders />} />
      <Route path="/certificate" element={<CertificateDetailed />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}


export default App;
