import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ax from "../../utils/ax";

const initialState = {
  items: [],
  bankList: []
};

export const postCreatePayment = createAsyncThunk(
  "payment/create",
  async (paymentData) => {
    try {
      const {data} = await ax().post('payment/create', paymentData)
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getBankList = createAsyncThunk(
  "bank/list",
  async (params) => {
    try {
      const {data} = await ax().post('bank/list', params)
      return data;
    } catch (error) {
      return error;
    }
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    createPayment(state, action) {
      const { item } = action.payload
      state.items.push(item)
    },
    createBankList: (state, action) => {
      const newBanks = action.payload.filter(newBank => 
        !state.bankList.some(existingBank => existingBank.bank_id === newBank.bank_id)
      );
      state.bankList.push(...newBanks);
    },
  }
})

export const { createPayment, createBankList } = paymentSlice.actions

export default paymentSlice.reducer
