export function formatNumber(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00a0");
};

export function cleanPhoneNumber(phone) {
  return phone.replace(/[\s-]/g, '');
};

export function handleSumCommission(count, method) {
  if(method === 'card' && +count > 0 & +count <= 500) {
    const commission = 50;
    return  (+count + commission + Math.ceil((+count / 0.87 - +count))).toFixed();
  } 
  return  (+count + Math.ceil((+count / 100 * 10)) + Math.ceil((+count / 0.87 - +count))).toFixed();
};

export function handleSumCommissionBy(count) {
  return (+count + Math.trunc((+count / 100 * 10)) + Math.trunc((+count / 0.87 - +count))).toFixed();
};

export function calculateNDFL(sum, tax, commission) {
  const taxRate = (100 - tax) / 100;
  const commissionRate = (+sum / 100) * parseFloat(commission);
  const totalSum = +sum + commissionRate;
  const ndflAmount = (totalSum / taxRate) - totalSum;

  return Math.round(ndflAmount);
};

export function calculateCommission(count, commission) {
  const commissionRate = parseFloat(commission);

  return Math.ceil((count / 100) * commissionRate);
};

export function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
};

export function openModal(scrollWidth) {
  document.body.classList.add('modal-open');
  updateBodyStyles(scrollWidth);
};

export function closeModal() {
  const body = document.body;

  body.classList.remove('modal-open');
  body.removeAttribute('style');
  body.removeAttribute('class');
  updateBodyStyles();
};

export function updateBodyStyles(scrollbarWidth) {
  const windowWidth = window.innerWidth;
  
  if (windowWidth >= 1024) {
    // const headerTop = document.querySelector('.header_top');
    const goUp = document.querySelector('.go_up');
    const scroll = scrollbarWidth ? `${scrollbarWidth}px` : '';
    
    document.body.style.marginRight = scroll;
    // headerTop.style.marginRight = windowWidth >= 1620 ? '' : scroll;
    if(goUp) {
      goUp.style.marginRight = scroll;
    };
  };
};

export function getPhotoUrl(file) {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    return fr;
};

export function getInitialState(stateKey) {
  const savedState = localStorage.getItem(stateKey);
  return savedState ? JSON.parse(savedState) : null;
};

export function reverseDate(dateString) {
  if (!dateString) return '';
  
  const parts = dateString.split('-');

  return parts.reverse().join('-');
};

export function formatCreatedDate(dateString, type, isStartOfDay = false, period = '') {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  let hours, minutes, seconds;

  if (isStartOfDay) {
    hours = '00';
    minutes = '00';
    seconds = '00';
  } else {
    hours = String(date.getHours()).padStart(2, '0');
    minutes = String(date.getMinutes()).padStart(2, '0');
    seconds = String(date.getSeconds()).padStart(2, '0');
  }

  if (isStartOfDay && period === 'custom') {
    hours = '23';
    minutes = '59';
    seconds = '59';
  }

  if (type === 'date') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  return `${day}.${month}.${year} – ${hours}:${minutes}:${seconds}`;
};

export function getDates(period = 'month') {
  const today = new Date();
  let startDate;

  if (period === 'month') {
      startDate = new Date();
      startDate.setDate(today.getDate() - 30);
  } else {
      startDate = new Date();
      startDate.setMonth(today.getMonth() - 3);
  }

  return {
      startDate: formatCreatedDate(startDate, 'date', true),
      endDate: formatCreatedDate(today, 'date')
  };
};

export function parseDate(dateString) {
  if (!dateString) return;
  const [datePart, timePart] = dateString.split(' ');
  let year, month, day;

  if (datePart.includes('.')) {
    [day, month, year] = datePart.split('.');
  } else {
    [year, month, day] = datePart.split('-');
  }
  const [hours, minutes, seconds] = timePart.split(':');
  
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

export function validateEmail(email) {
  const trimmedEmail = email.trim();
  
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  
  if (!trimmedEmail) {
    return "Поле не заполнено";
  }
  if (trimmedEmail !== email) {
    return "Email не должен содержать пробелы в начале или конце";
  }
  if (email.includes(' ')) {
    return "Email не должен содержать пробелы";
  }
  if (!email.includes('@')) {
    return "В адресе электронной почты обязательно должен присутствовать символ '@'";
  }
  if (email.match(/@/g).length > 1) {
    return "Адрес электронной почты не должен содержать более одного символа '@'";
  }
  if (!email.match(/\w@\w/)) {
    return "Адрес электронной почты должен содержать как минимум один символ до и после символа '@'";
  }
  if (email.length > 64) {
    return "Ограничение на 64 символа включительно в доменной и пользовательской части";
  }
  if (!emailRegex.test(email)) {
    return "Неверный формат email";
  }
  return null;
};

export function validateInput(value, type) {
  if (type === 'text') {
    return value.replace(/[^a-zA-Zа-яА-Я]/g, '');
  } else if (type === 'number') {
    return value.replace(/[^0-9]/g, '');
  }
  return value;
};

export const getStringDeclination = (number = 0, word = '') => {
  if(number === null || number === undefined) return '';

  switch (word) {
    case 'бренд':
      if(number % 10 === 1 && number !== 11) {
        return `${number} бренд`;
      }

      if(number % 10 >= 2 && number % 10 <= 4 && (number < 12 || number > 14)) {
          return `${number} бренда`;
      }

      return `${number} брендов`;

    default:
    break;
  }
};

export const validateSum = (sum, maxSum, minSum, userPoints, type) => {
  const errors = [];

  if (!sum || sum.length === 0) {
    errors.push("Неверная сумма");
  } else {
    const parsedSum = parseFloat(sum);
    
    if (isNaN(parsedSum)) {
      errors.push("Неверная сумма");
    } else {
      if (parsedSum < minSum) {
        errors.push(`Минимальная сумма: ${minSum}`);
      }
      if (parsedSum > maxSum) {
        errors.push(`Максимальная сумма: ${maxSum}`);
      }
      if (parsedSum > userPoints) {
        errors.push("Недостаточно баллов");
      }
    }
  }

  return errors;
};

export const validateNumber = (currentNumber, number, type, sbp = false) => {
  const errors = [];

  if (type === 'phone') {
    const cleanedPhoneNumber = cleanPhoneNumber(currentNumber);
    if (cleanedPhoneNumber !== number) {
      errors.push('Номер телефона не соответствует зарегистрированному в профиле');
    } 
  }

  if (type === 'card') {
    if (!sbp && (currentNumber.includes('_') || currentNumber.replace(/_/g, '').length !== 19)) {
      errors.push('Некорректный номер банковской карты');
    }
    if (sbp) {
      if (currentNumber.includes('_') || currentNumber === '') {
        errors.push('Некорректный номер телефона');
      }
    }
  }

  return errors;
};

export function calculateTotalSum(item) {
  const commission = parseFloat(item.data.user_markup || 0) / 100;

  let priceWithoutCommission = item.nominal * item.quantity;
  let commissionAmount = priceWithoutCommission * commission;

  let taxableAmount = priceWithoutCommission + commissionAmount;
  let tax = Math.round(taxableAmount * (item.tax / 100) / (1 - item.tax / 100));

  let total = priceWithoutCommission + commissionAmount + tax;

  const discount = parseFloat(item.data.user_discount || 0) / 100;
  if (discount > 0) {
    total *= (1 - discount);
  }
  
  return Math.round(total);
}

export function isColumnShown(items) {
  let showCommission = false;
  let showDiscount = false;
  
  items.forEach(item => {
    if (item.user_markup > 0) showCommission = true;
    if (item.user_discount > 0) showDiscount = true;
  });

  return { showCommission, showDiscount };
};

export function handleOnKeyDownValidate(event) {
  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];

  if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
};

export function handleOnChangeCommissionSum(event, setCount, handleSumWarning) {
  let value = event.target.value;

  if (value.length === 0) {
    setCount(value);
    handleSumWarning(value);
  } else {
    if (value.length === 1 && value[0] === ".") {
    } else {
      if (
        !value.substring(0, value.length - 1).includes(".") &&
        value[value.length - 1] === "."
      ) {
        setCount(value);
        handleSumWarning(value);
      } else {
        setCount(parseFloat(value) + "");
        handleSumWarning(parseFloat(value) + "");
      }
    }
  }
};