import { useSelector, useDispatch } from "react-redux";
import React, {useEffect, useState} from "react";
import { useMediaQuery } from "react-responsive";
import MaskedInput from "react-text-mask";
import { useLocation } from 'react-router-dom';

import ErrorModal from "../ErrorModal/ErrorModal";
import { ndflUpdate } from "../../actions/widgetActions";
import Button from "../Button/Button";
import LeavingModal from "../LeavingModal/LeavingModal";
import { getScrollbarWidth, getPhotoUrl, openModal, validateInput } from "../../utils/helpers";
import NDFLStatus from "../NDFLStatus/NDFLStatus";
import { EditProfileSend, updateUser } from "../../redux/reducers/userReducer";
import { getNDFLStatus, postNDFLForm } from "../../redux/reducers/ndflReducer";
import NDFLFormWarning from "../NDFLFormWarning/NDFLFormWarning";
import NDFLWarning from "../NDFLWarning/NDFLWarning";
import InfoSelfi from "../InfoSelfi/InfoSelfi";
import ImageDragAndDrop from "../ImageDragAndDrop/ImageDragAndDrop";
import PhotoError from "../PhotoError/PhotoError";

import "./ndfl_form.css";

const NDFLForm = () => {
  let location = useLocation();
  const dispatch = useDispatch();

  const listLang = useSelector((state) => state.listLang);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const ndfl = useSelector((state) => state.ndfl);
  const user = useSelector((state) => state.user);
  const [first_name, setFirsName] = useState(ndfl.first_name || user.first_name);
  const [patronymic, setMiddleName] = useState(ndfl.patronymic || user.patronymic);
  const [last_name, setLastName] = useState(ndfl.last_name || user.last_name);
  const [date_of_birth, setDateOfBirth] = useState(ndfl.date_of_birth || user.date_of_birth);
  const [inn, setInn] = useState(null);
  const [passportCode, setPassportCode] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [address, setAddress] = useState('');
  const [files, setFiles] = useState({});
  const [postFiles, setPostFiles] = useState({});
  const [messages, setMessages] = useState([]);
  const [ndflStatus, setNdflStatus] = useState(ndfl.status);
  const [isHandle, setIsHandle] = useState(null);
  const [isAutoRecognize, setIsAutoRecognize] = useState(null);
  const [isSelfi, setIsSelfi] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isNext, setIsNext] = useState(false);

  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [patronymicError, setPatronymicError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [innError, setInnError] = useState(false);
  const [passportCodeError, setPassportCodeError] = useState(false);
  const [passportNumberError, setPassportNumberError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [photoError, setPhotoError] = useState(null);

  const scrollWidth = getScrollbarWidth();

  const wherePromptVisible =
    !!first_name ||
    !!patronymic ||
    !!last_name ||
    !!inn ||
    !!passportCode ||
    !!address ||
    !!passportNumber;

  const fullField = !!(
    first_name.length > 1 &&
    patronymic.length > 1 &&
    last_name.length > 1 &&
    date_of_birth &&
    !dateError &&
    inn &&
    !innError &&
    passportCode &&
    !passportCode.includes('_') &&
    address &&
    passportNumber &&
    !passportNumber.includes('_') &&
    Object.keys(files).length > 0 &&
    files.file1);

  const ndflData = {
    first_name,
    last_name,
    patronymic,
    inn,
    date_of_birth: ndfl.date_of_birth,
    series_documents: passportCode,
    number_documents: passportNumber,
    residence_address: address,
    photo_passport_spread_file: postFiles.file1 || '',
    photo_passport_registration_file: postFiles.file2 || '',
  };

  const ndflDataReady = [
    {title: 'ФИО', content: `${last_name} ${first_name} ${patronymic}`},
    {title: 'Номер и серия паспорта', content: `${passportCode} ${passportNumber}`},
    {title: 'Дата рождения', content: `${date_of_birth}`},
    {title: 'ИНН', content: inn},
    {title: 'Адрес', content: address}
  ];
  
  const handleFilesChange = (e, fileKey) => {
    if (e.target.files) {
      const preImg = getPhotoUrl(e.target.files[0]);
      setPostFiles({...postFiles, [fileKey]: e.target.files[0]})
      preImg.onload = () => setFiles({...files, [fileKey]: preImg.result});
    }
  };

  const handlePostData = () => {
    dispatch(postNDFLForm(ndflData)).then(res => {
      if (res.payload.success) {
        dispatch(getNDFLStatus()).then(res => {
          const data = res.payload.data;
          setNdflStatus(data.status);
          dispatch(ndflUpdate({...data}))
        });

        dispatch(EditProfileSend({
          first_name,
          last_name,
          patronymic,
          date_of_birth: ndfl.date_of_birth,
          phone: user.phone,
          email: user.email,
          gender: user.gender,
        })).then(res => {
          if (res.payload.success) {
            dispatch(updateUser({...res.meta.arg}));
          }
        });

        setNdflStatus(ndfl.status);
        setIsHandle(null);
        setIsNext(false);
        setIsChecked(false);
        setMessages([
          {
            title: "Изменения прошли успешно!",
            message: "",
            firstColor: "#000",
            secondColor: "#000",
          },
        ]);
      } else {
        const data = res.payload.response.data.data;
        const messages = [];

        for (const key in data) {
          if (data.hasOwnProperty(key) && Array.isArray(data[key])) {
            messages.push(data[key][0]);
          }
        };
        
        setMessages([
        {
          title: res.payload.response.data.message || "Ошибка при сохранении данных",
          message: messages[0] || '',
          firstColor: "#000",
          secondColor: "#000",
        },
      ]);
      }
    }) 
  };

  const handleClickNext = () => {
    setIsNext(true);
    setIsSelfi(true)
  };

  const handleClickFullFieldData = () => {
    setIsNext(true);
    setIsAutoRecognize(false);
  };

  const validErrors = () => {
    const checks = [
      checkNameError(last_name, setLastNameError),
      checkNameError(first_name, setFirstNameError),
      checkNameError(patronymic, setPatronymicError),
      checkInnError(inn),
      checkPassportError(passportCode, setPassportCodeError),
      checkPassportError(passportNumber, setPassportNumberError),
      checkAddressError(address, setAddress),
    ];

    const hasErrors = checks.some(result => result === 1);

    return hasErrors;
  };

  const handleClickSend = () => {
    const valid = validErrors();

    if (!valid) {
      handlePostData();
      openModal(scrollWidth);
    };
  };

  const checkNameError = (value, setError) => {
    const validateName = value.length < 2 || value.length > 30;
    
    setError(validateName);

    return validateName;
  };

  const renderNdflData = () => {
    return (
      <div className="ndfl-form__data-container">
        {ndflDataReady.map((item) => {
          return (
            <div className="ndfl-form___data-content">
              <h2 className="ndfl-form__data-title">{item.title}</h2>
              <p className="ndfl-form__data-text">{item.content}</p>
            </div>
          )
        })}
      </div>
    )
  };

  const renderErrorName = (value) => (
    value.length < 2
      ? <b className="ndfl-form__warning">Мало символов</b>
      : value.length > 30
        ? <b className="ndfl-form__warning">Много символов</b>
        : <br/>
  );

  const renderInputDateError = (value) => {
    return (
      !value || value.includes('_')
        ? <b className="ndfl-form__warning">Дата не заполнена</b>
        : <br/>
    );
  };

  const checkDateError = (value) => {
    const currentValue = value || date_of_birth;

    setDateError(!value || currentValue.includes('_'));
  };

  const checkInnError = (value) => {
    const validateValue = value.length < 12 || value.length > 12;

    setInnError(validateValue);

    return validateValue;
  };

  const renderErrorInn = (value) => (
    !value || value.length < 12
      ? <b className="ndfl-form__warning">Мало символов</b>
      : value.length > 12
        ? <b className="ndfl-form__warning">Много символов</b>
        : <br/>
  );

  const renderErrorPassport = (value) => (
    !value || value.includes('_')
      ? <b className="ndfl-form__warning">Поле не заполнено</b>
      : <br/>
  );

  const checkPassportError = (value, setError) => {
    const validate = !value || value.includes('_');

    setError(validate);

    return validate;
  };

  const renderAddressPassport = (value) => (
    !value
      ? <b className="ndfl-form__warning">Поле не заполнено</b>
      : <br/>
  );

  const checkAddressError = (value) => {
    const validate = !value;
    
    setAddressError(validate);

    return validate;
  };

  useEffect(() => {
    return () => {
      window.close();
    }
  }, [window]);

  useEffect(() => {
    dispatch(getNDFLStatus()).then(res => {
      const data = res?.payload?.data || '';
      setNdflStatus(data.status)
      dispatch(ndflUpdate({...data}))
    })
  }, [dispatch]);

  const renderInputFieldMask = (title, placeholder, value, onChange, mask, error, errorValue) => {
    return (
      <div className="ndfl-form__field_wrapper">
        <b className="ndfl-form__field-header">{title}</b>
        <MaskedInput
          type={'tel'}
          value={value}
          placeholder={placeholder}
          className={`input ndfl-form__input ${errorValue ? 'input_warning' : ''}`}
          mask={mask}
          onChange={onChange}
        />
        {errorValue ? error(value) : <br />}
      </div>
    );
  };

  const renderInputField = (title, placeholder, value, onChange, error, renderError, typeKeyboard = 'text') => {
    const handleInputChange = (event) => {
      const validatedValue = validateInput(event.target.value, typeKeyboard);
      onChange({ target: { value: validatedValue } });
    };
    return (
      <div className="ndfl-form__field_wrapper">
        <b className="ndfl-form__field-header">{title}</b>
        <input
          value={value}
          type={typeKeyboard}
          readOnly={!isHandle}
          placeholder={placeholder}
          className={`
            input 
            ndfl-form__input 
            ${error ? 'input_warning' : ''} 
            ${!isHandle ? 'ndfl-form__input_readonly' : ''}
          `}
          onChange={handleInputChange}
        />
        {error ? renderError(value) : <br />}
      </div>
    );
  };

  const renderAddressField = () => {
    return (
      <div className="ndfl-form__field_wrapper">
        <b className="ndfl-form__field-header">Адрес места жительства {listLang.rusCountrySymbol}</b>
        <textarea
          value={address}
          placeholder={""}
          readOnly={!isHandle}
          className={`
            ndfl-form__text_area 
            ${addressError ? 'input_warning' : ''} 
            ${!isHandle ? 'ndfl-form__input_readonly' : ''}
          `}
          onChange={(t) => {
            setAddress(t.target.value);
            checkAddressError(t.target.value);
          }}
        />
        {addressError ? renderAddressPassport(address) : <br />}
      </div>
    );
  };

  const renderPassport = () => {
    return (
      <div className="ndfl-form__passport">
        <div className="ndfl-form__seria">
          <b className="ndfl-form__passport_title">Серия паспорта</b>
          <MaskedInput
            type="text"
            value={passportCode}
            placeholder={"XXXX"}
            readOnly={!isHandle}
            className={`
              input 
              ndfl-form__seria_value 
              ${passportCodeError ? 'input_warning' : ''} 
              ${!isHandle ? 'ndfl-form__input_readonly' : ''}
            `}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            onChange={(t) => {
              setPassportCode(t.target.value);
              checkPassportError(t.target.value, setPassportCodeError);
            }}
          />
          {passportCodeError ? renderErrorPassport(passportCode) : <br />}
        </div>
        <div className="ndfl-form__number">
          <b className="ndfl-form__passport_title">Номер паспорта</b>
          <MaskedInput
            type="text"
            value={passportNumber}
            placeholder={"XXXXXX"}
            readOnly={!isHandle}
            className={`
              input 
              ndfl-form__number_value 
              ${passportNumberError ? 'input_warning' : ''} 
              ${!isHandle ? 'ndfl-form__input_readonly' : ''}
            `}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            onChange={(t) => {
              setPassportNumber(t.target.value);
              checkPassportError(t.target.value, setPassportNumberError);
            }}
          />
          {passportNumberError ? renderErrorPassport(passportNumber) : <br />}
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className="ndfl-form__description">
        <label className="ndfl-form__checkbox-label">
          <input 
            type="checkbox"
            className="ndfl-form__checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <span className="ndfl-form__checkmark"></span>
        </label>
        <div>
          <p className="ndfl-form__description-text">
            Нажимая кнопку «Отправить», я подтверждаю,
            что я ознакомлен и согласен с условиями{" "}
            <a href="/rules.pdf" target={'_blank'} className="input__ndfl-link">
              политики обработки персональных данных
            </a>{" "}
            .
          </p>
        </div>
      </div>
    );
  };
  
  const renderSend = () => {
    return (
      <Button
        customClass={`button_save ndfl-form__button`}
        onClick={handleClickSend}
        disabled={!isSelfi || !isChecked || !files.file2}
        title="Подтвердить и отправить"
      />
    );
  };

  const renderNext = () => {
    return (
      <Button
        customClass={`button_save ndfl-form__button`}
        onClick={handleClickNext}
        disabled={!fullField}
        title="Далее"
      />
    );
  };

  const renderAutoRecognize = () => {
    return (
      <Button
        customClass={`button_save ndfl-form__button`}
        onClick={handleClickFullFieldData}
        disabled={!files.file1}
        title="Распознать данные"
      />
    );
  };

  if (isMobile) {
    return (
      <div className="ndfl-form">
        <div className="ndfl-form__wrapper-content">
          <h1 className="ndfl-form__header">Анкета {listLang.taxSystem}</h1>
          {ndfl.status !== "confirmed" ? <NDFLFormWarning /> : null}
          <LeavingModal wherePromptVisible={wherePromptVisible}/>
          <NDFLStatus 
            status={ndflStatus} 
            ndflId={ndfl.taxes_id} 
            createdDate={ndfl.created_at} 
            comment={ndfl.comment} 
            setNdflStatus={setNdflStatus}
            isHandle={isHandle}
            setIsHandle={setIsHandle}
            isAutoRecognize={isAutoRecognize}
            setIsAutoRecognize={setIsAutoRecognize}
          />
          {isHandle !== null && isHandle && !isNext  ? (
            <div className="ndfl-form__container">
              <div className="ndfl-form__fio-container">
                {renderInputField(
                  "Фамилия",
                  "",
                  last_name,
                  (t) => {
                    setLastName(t.target.value);
                    checkNameError(t.target.value, setLastNameError);
                  },
                  lastNameError,
                  renderErrorName
                )}
                {renderInputField(
                  "Имя",
                  "",
                  first_name,
                  (t) => {
                    setFirsName(t.target.value);
                    checkNameError(t.target.value, setFirstNameError);
                  },
                  firstNameError,
                  renderErrorName
                )}
                {renderInputField(
                  "Отчество",
                  "",
                  patronymic,
                  (t) => {
                    setMiddleName(t.target.value);
                    checkNameError(t.target.value, setPatronymicError)
                  },
                  patronymicError,
                  renderErrorName
                )}
                {renderInputFieldMask(
                  "Дата рождения",
                  "",
                  date_of_birth,
                  (t) => {
                    setDateOfBirth(t.target.value);
                    checkDateError(t.target.value);
                  },
                  [/[0-3]/, /\d/, '-', /[0-1]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                  renderInputDateError,
                  dateError
                )}
              </div>
              {renderInputField(
                listLang.individualNumberText || 'ИНН',
                "NNNNXXXXXXCC",
                inn,
                (t) => {
                  setInn(t.target.value);
                  checkInnError(t.target.value);
                },
                innError,
                renderErrorInn,
                'number'
              )}
              {renderPassport()}
              {renderAddressField()}
              {isHandle ? (
                <h2 className="ndfl-form__passport-photo-title">Добавить фото разворота паспорта</h2>
              ) : null}
              {photoError ? (
                <PhotoError error={photoError} />
              ) : null}
              <ImageDragAndDrop 
                setIsSelfi={setIsSelfi}
                setFiles={setFiles}
                files={files}
                setPostFiles={setPostFiles}
                postFiles={postFiles}
                title={"Разворот паспорта"}
                id={"file1"}
                error={photoError}
                setPhotoError={setPhotoError}
              />
              {renderNext()}
            </div>
          ) : null}
          {isAutoRecognize ? (
            <div className="ndfl-form__autorecognize-container">
              <h2 className="ndfl-form__passport-photo-title">Добавить фото разворота паспорта для автоматического распознавание</h2>
              {photoError ? (
                <PhotoError error={photoError} />
              ) : null}
              <ImageDragAndDrop 
                setIsSelfi={setIsSelfi}
                setFiles={setFiles}
                files={files}
                setPostFiles={setPostFiles}
                postFiles={postFiles}
                title={"Разворот паспорта"}
                id={"file1"}
                error={photoError}
                setPhotoError={setPhotoError}
              />
              {renderAutoRecognize()}
            </div>
          ) : null}
          {isNext ? (
            <>
              {renderNdflData()}
              <InfoSelfi />
              <h2 className="ndfl-form__passport-photo-title">Добавить селфи с разворотом паспорта</h2>
              {photoError ? (
                <PhotoError error={photoError} />
              ) : null}
              <ImageDragAndDrop 
                setIsSelfi={setIsSelfi}
                setFiles={setFiles}
                files={files}
                setPostFiles={setPostFiles}
                postFiles={postFiles}
                title={"Селфи с паспортом"}
                id={"file2"}
                error={photoError}
                setPhotoError={setPhotoError}
              />
              {isHandle ? renderDescription() : null}
              {renderSend()}
            </>
          ) : null}
          <ErrorModal messages={messages} setMessages={setMessages} />
        </div>
        {ndfl.status !== 'confirmed' ? <NDFLWarning /> : null}
      </div>
    );
  };

  return (
    <div className="ndfl-form">
      <div className="ndfl-form__wrapper-content">
        <h1 className="ndfl-form__header">Анкета {listLang.taxSystem}</h1>
        {ndfl.status !== "confirmed" ? <NDFLFormWarning /> : null}
        <LeavingModal wherePromptVisible={wherePromptVisible}/>
        <NDFLStatus 
          status={ndflStatus} 
          ndflId={ndfl.taxes_id} 
          createdDate={ndfl.created_at} 
          comment={ndfl.comment} 
          setNdflStatus={setNdflStatus}
          isHandle={isHandle}
          setIsHandle={setIsHandle}
          isAutoRecognize={isAutoRecognize}
          setIsAutoRecognize={setIsAutoRecognize}
        />
        {isHandle !== null && isHandle && !isNext ? (
          <div className="ndfl-form__container">
            <h1 className="ndfl-form__handle-title">Заполнить данные вручную</h1>
            <div className="ndfl-form__fio-container">
              {renderInputField(
                "Фамилия",
                "",
                last_name,
                (t) => {
                  setLastName(t.target.value);
                  checkNameError(t.target.value, setLastNameError);
                },
                lastNameError,
                renderErrorName
              )}
              {renderInputField(
                "Имя",
                "",
                first_name,
                (t) => {
                  setFirsName(t.target.value)
                  checkNameError(t.target.value, setFirstNameError);
                },
                firstNameError,
                renderErrorName
              )}
              {renderInputField(
                "Отчество",
                "",
                patronymic,
                (t) => {
                  setMiddleName(t.target.value);
                  checkNameError(t.target.value, setPatronymicError);
                },
                patronymicError,
                renderErrorName
              )}
              {renderInputFieldMask(
                "Дата рождения",
                "",
                date_of_birth,
                (t) => {
                  setDateOfBirth(t.target.value);
                  checkDateError(t.target.value);
                },
                [/[0-3]/, /\d/, '-', /[0-1]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                renderInputDateError,
                dateError
              )}
            </div>
            <div>
              {renderInputField(
                listLang.individualNumberText  || 'ИНН',
                "NNNNXXXXXXCC",
                inn,
                (t) => {
                  setInn(t.target.value);
                  checkInnError(t.target.value);
                },
                innError,
                renderErrorInn,
                'number'
              )}
              {renderPassport()}
              {renderAddressField()}
              {isHandle ? (
                <h2 className="ndfl-form__passport-photo-title">Добавить фото разворота паспорта</h2>
              ) : null}
              {photoError ? (
                <PhotoError error={photoError} />
              ) : null}
              <ImageDragAndDrop 
                setIsSelfi={setIsSelfi}
                setFiles={setFiles}
                files={files}
                setPostFiles={setPostFiles}
                postFiles={postFiles}
                title={"Разворот паспорта"}
                id={"file1"}
                error={photoError}
                setPhotoError={setPhotoError}
              />
              {renderNext()}
            </div>
          </div>
        ) : null}
        {isAutoRecognize ? (
          <div className="ndfl-form__autorecognize-container">
            <h2 className="ndfl-form__passport-photo-title">Добавить фото разворота паспорта для автоматического распознавание</h2>
            {photoError ? (
              <PhotoError error={photoError} />
            ) : null}
            <ImageDragAndDrop 
              setIsSelfi={setIsSelfi}
              setFiles={setFiles}
              files={files}
              setPostFiles={setPostFiles}
              postFiles={postFiles}
              title={"Разворот паспорта"}
              id={"file1"}
              error={photoError}
              setPhotoError={setPhotoError}
            />
            {renderAutoRecognize()}
          </div>
        ) : null}
        {isNext ? (
          <>
            {renderNdflData()}
            <InfoSelfi />
            <h2 className="ndfl-form__passport-photo-title">Добавить селфи с разворотом паспорта</h2>
            {photoError ? (
              <PhotoError error={photoError} />
            ) : null}
            <ImageDragAndDrop 
              setIsSelfi={setIsSelfi}
              setFiles={setFiles}
              files={files}
              setPostFiles={setPostFiles}
              postFiles={postFiles}
              title={"Селфи с паспортом"}
              id={"file2"}
              error={photoError}
              setPhotoError={setPhotoError}
            />
            {renderDescription()}
            {renderSend()}
          </>
        ) : null}
        <ErrorModal messages={messages} setMessages={setMessages} />
      </div>
      {ndfl.status !== 'confirmed' ? <NDFLWarning /> : null}
    </div>
  );
};

export default NDFLForm;